body {
    background: #5cd5e6 url('../../assets/sportsfishies.png') no-repeat fixed left;
    background-size: cover;
    color: #000;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

body.lowprofile {
    background: #999;
}

div {
    box-sizing: border-box;
}

input {
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(189, 56, 130, 0.5);
    font-size: 18px;
    font-weight: bold;
    width: 80%;
}

select {
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(189, 56, 130, 0.5);
    -webkit-border-radius: 0;
    font-size: 18px;
    font-weight: bold;
}

.semitransparent {
    background-color: rgba(225, 255, 240, 0.80);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    padding: 15px;
    width: 100%;
}

.left {
    float: left;
    width: 40%;
    margin-right: 7px;
}

.right {
    float: right;
    width: 60%;
    margin-left: 7px;
}

button {
    background: rgba(189, 56, 130, 1);
    color: #fff;
    font-size: 18px;
    padding: 10px;
}

video {
    width: 100%;
}

i.rule {
    font-size: 12px;
}