.admin {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.gradecontainer table {
    border-spacing: 0;
}

.gradecontainer tr:nth-child(even){
    background-color: rgba(138, 138, 138, 0.45);
}

.gradecontainer td {
    padding: 5px;
}

.gradecontainer input {
    margin-bottom: 5px;
}

.manage-top {
    display: flex;
    margin-bottom: 15px;
    vertical-align: center;
    text-align: center;
}

.manage-question {
    width: 50%;
    margin-right: 7px;
}

.manage-controls {
    width: 50%;
}

.management {
    margin-top: 15px;
}

.management .semitransparent {
    padding-top: 0px;
    background: none;
    box-shadow: none;
}

.management #question {
    margin-top: 0
}

textarea {
    border: none;
    margin: 5px;
    font-size: 16px;
    width: 200px;
    height: 100px;
}

.text-editor {
    width: 400px;
}

.question-row {
}

.question-row img {
    display: inline-block;
    width: 200px;
}

.adminheader {
    margin-bottom: 50px;
}

.duplicate-answer {
    color: #ec0000;
}