#header ::placeholder {
    color: #ba3b82;
}

#header input {
    width: 175px;
}

#header select {
    margin-top: 10px;
    margin-left: 5px;
}

#game {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

@media screen and (max-width: 900px) {
    #game {
        display: block;
    }
}

div#question {
    margin-top: 15px;
    width: 100%;
}

#bonus {
    margin-top: 15px;
    padding: 0px;
}

#bonus input {
    margin: 10px;
}

#bonus div {
    margin-bottom: 15px;
}

#bonus div.picturecard {
    padding: 0;
}

#bonus .button {
    padding: 10px;
}


img {
    width: 100%;
}

div#answer {
    margin-top: 15px;
    width: 100%;
}

#answer input {
    font-weight: 300;
}

#overview {
    width: 100%;
    margin-bottom: 15px;
}

#overview table {
    border-spacing: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

#overview td {
    padding: 10px;
    text-align: left;
}

.overview-cell {
    width: 97%;
}

#overview .grading {
    background-color: transparent;
    animation-name: grading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.flashing {
    background-color: transparent;
    animation-name: grading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.overview-cell {
    padding: 10px;
    height: 100%;
}

@keyframes grading {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: rgba(0, 0, 0, 0.2);
    }
    100% {
        background-color: transparent;
    }
}

.correct {
    background-color: rgba(66, 222, 115, 0.58);
}

.incorrect {
    background-color: rgba(192, 120, 120, 0.5);
}

#overview {
    font-size: 16px;
}

#overview img {
    width: 150px;
}

.phase-name {
    font-size: 18px;
}

.points-modified {
    text-decoration: line-through;
}

#graph {
    margin-top: 15px;
    width: 100%;
}

#line-graph {
    height: 350px;
}


audio {
    margin-top: 10px;
    width: 100px;
}

.standby {
    width: 300px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}

.question-row {
    margin-top: 10px;
    width: 100%;
}

.incorrect-answer {
    text-decoration: line-through;
}

.answer-container {

}

.answer-left {
    display: inline-block;
}

.answer-right {
    display: inline-block;
}

.pending-answers-table {
    text-align: left;
    border-collapse: collapse;
}

.check-icon {
    font-size: 24px;
}

.horizontal-question-wrapper {
    display: flex;
}

.horizontal-question-wrapper #answer {
    margin-left: 15px;
}